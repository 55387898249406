import { gql } from "@apollo/client";

const GET_ENUM_VALUES = gql`
  query GetEnumValues {
    accessType: __type(name: "AccessType") {
      enumValues {
        name
      }
    }
    achievementTriggerType: __type(name: "AchievementTriggerType") {
      enumValues {
        name
      }
    }
    challengeStatus: __type(name: "ChallengeStatus") {
      enumValues {
        name
      }
    }
    trackSource: __type(name: "TrackSource") {
      enumValues {
        name
      }
    }
    activityType: __type(name: "ActivityType") {
      enumValues {
        name
      }
    }
    activityReportStatus: __type(name: "ActivityReportStatus") {
      enumValues {
        name
      }
    }
    activityReportReason: __type(name: "ActivityReportReason") {
      enumValues {
        name
      }
    }
    activityReviewDecision: __type(name: "ActivityReviewDecision") {
      enumValues {
        name
      }
    }
    videoEncodingStatus: __type(name: "VideoEncodingStatus") {
      enumValues {
        name
      }
    }
    videoApprovalStatus: __type(name: "VideoApprovalStatus") {
      enumValues {
        name
      }
    }
    videoType: __type(name: "VideoType") {
      enumValues {
        name
      }
    }
    classVideoType: __type(name: "ClassVideoType") {
      enumValues {
        name
      }
    }
  }
`;

export default GET_ENUM_VALUES;
