const REQUIRED = ["title", "startTime", "endTime", "challengeStatus"] as const;

type RequiredFields = (typeof REQUIRED)[number];

interface ChallengeValues {
  title?: string;
  startTime?: string;
  endTime?: string;
  challengeStatus?: string;
  displayText?: {
    earnedByHeader?: string;
    goalHeader?: string;
    subtitleHeader?: string;
  };
}

type ValidationErrors = {
  [key in RequiredFields]?: string[];
};

export function validateChallenge(values: ChallengeValues): ValidationErrors {
  const errors: ValidationErrors = {};

  REQUIRED.forEach((requiredField) => {
    if (!values[requiredField]) {
      errors[requiredField] = ["ra.validation.required"];
    }
  });

  return errors;
}
