import { useRecordContext, Record } from "react-admin";
import { format } from "date-fns";
import React from "react";
import { DATE_FORMAT } from "./constants";

interface DateTimeFieldProps {
  source: string;
  label?: string;
  addLabel?: boolean;
  record?: Record;
  [key: string]: any;
}

export function DateTimeField({
  source,
  ...props
}: DateTimeFieldProps): JSX.Element {
  const record = useRecordContext(props);

  const datetime = record?.[source];
  return <span>{datetime ? format(new Date(datetime), DATE_FORMAT) : ""}</span>;
}

DateTimeField.defaultProps = {
  addLabel: true,
  label: undefined,
};
