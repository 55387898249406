import React from "react";
import {
  ArrayField,
  Datagrid,
  NumberField,
  TextField,
  useRecordContext,
  FunctionField,
} from "react-admin";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { DateTimeField } from "lib/components/DateTime";
import { Achievement } from "types/graphql";
import { SVGFromUrl } from "lib/components/SVGFromUrl";
import { AddBadge } from "./AddBadge";
import { RemoveBadgeField } from "./RemoveBadgeField";
import { EditBadgeButton } from "./EditBadgeButton";

const useStyles = makeStyles(() => ({
  badgeImage: {
    width: "50px",
    height: "50px",
  },
}));

interface BadgesProps {
  source?: string;
}

export function Badges(props: BadgesProps): JSX.Element {
  const achievementRecord = useRecordContext<Achievement>(props);
  const classes = useStyles();

  return (
    <Box>
      <Box display="flex" alignItems="center" p={1}>
        <Typography variant="h6">Badges</Typography>
        <AddBadge achievementId={achievementRecord.id} />
      </Box>

      <ArrayField source="badges">
        <Datagrid>
          <TextField source="name" />
          <TextField source="description" />
          <NumberField source="amountToEarn" />
          <FunctionField
            label="Active Badge"
            render={(record) => {
              if (!record?.activeImageUrl) {
                return null;
              }

              return (
                <div className={classes.badgeImage}>
                  <SVGFromUrl url={record.activeImageUrl} />
                </div>
              );
            }}
          />
          <FunctionField
            label="Inactive Badge"
            render={(record) => {
              if (!record?.inactiveImageUrl) {
                return null;
              }

              return (
                <div className={classes.badgeImage}>
                  <SVGFromUrl url={record.inactiveImageUrl} />
                </div>
              );
            }}
          />
          <DateTimeField source="createdAt" label="Created At" />
          <DateTimeField source="updatedAt" label="Updated At" />
          <EditBadgeButton />
          <RemoveBadgeField />
        </Datagrid>
      </ArrayField>
    </Box>
  );
}
