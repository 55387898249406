export enum AchievementHelpItem {
  CREATE_TITLE = "CREATE_TITLE",
  EDIT_TITLE = "EDIT_TITLE",
  NAME = "NAME",
  DESCRIPTION = "DESCRIPTION",
  TRIGGER_TYPE = "TRIGGER_TYPE",
  CHALLENGE = "CHALLENGE",
  BADGES = "BADGES",
}

type HelpInfo = [string, string];

const HELP_INFO_MAP: Record<AchievementHelpItem, HelpInfo> = {
  [AchievementHelpItem.CREATE_TITLE]: [
    "Create Achievement",
    "Create a new achievement that users can earn",
  ],
  [AchievementHelpItem.EDIT_TITLE]: [
    "Edit Achievement",
    "Modify an existing achievement's details",
  ],
  [AchievementHelpItem.NAME]: [
    "Name",
    "Enter a unique name for the achievement that will be displayed to users",
  ],
  [AchievementHelpItem.DESCRIPTION]: [
    "Description",
    "Enter a description explaining how users can earn this achievement",
  ],
  [AchievementHelpItem.TRIGGER_TYPE]: [
    "Trigger Type",
    "Select how this achievement will be triggered/earned by users",
  ],
  [AchievementHelpItem.CHALLENGE]: [
    "Challenge",
    "Select a challenge that will award this achievement when completed",
  ],
  [AchievementHelpItem.BADGES]: [
    "Badges",
    "View and manage the badges that have been awarded to users for this achievement",
  ],
};

export const compileHelpInfo = (items: AchievementHelpItem[]): HelpInfo[] =>
  items.map((item) => HELP_INFO_MAP[item]);
