import { client } from "../gqlClient";
import {
  CREATE_INSTRUCTOR,
  CREATE_INSTRUCTOR_ASSET,
  GET_ALL_INSTRUCTOR_CREDITS,
  GET_LIST_INSTRUCTORS,
  GET_ONE_INSTRUCTOR,
  UPDATE_INSTRUCTOR,
} from "./schema";
import {
  convertAdminPgToGraphqlRelayPg,
  convertAdminSortToGraphqlSort,
  uploadAssetToS3,
} from "../utils";

export async function instructorBulkCreate(params) {
  const { data } = params;

  const results = await Promise.allSettled(
    data.map(async (instructor) => {
      const {
        data: { id },
      } = await instructorCreate({ data: { name: instructor.name } });
      return instructorUpdate({ data: { ...instructor, id } });
    })
  );

  return {
    success: results.filter((r) => r.status === "fulfilled").length,
    errors: results.filter((r) => r.status === "rejected").length,
  };
}

export async function getAllInstructorCredits() {
  const data = await client.query(
    GET_ALL_INSTRUCTOR_CREDITS,
    null,
    "instructorCredits"
  );

  return {
    data,
    total: data.length,
  };
}

export async function instructorCreate(params) {
  const { data } = params;

  const res = await client.mutate(CREATE_INSTRUCTOR, {
    input: {
      ...data,
    },
  });

  const instructor = res.adminInstructorCreate;

  return {
    data: instructor,
  };
}

export async function getListInstructors(params) {
  const data = await client.query(
    GET_LIST_INSTRUCTORS,
    {
      filter: params.filter,
      pagination: convertAdminPgToGraphqlRelayPg(params.pagination),
      sort: convertAdminSortToGraphqlSort(params.sort),
    },
    "instructors"
  );

  return {
    data: data.edges.map((e) => ({
      hasMedia: {
        avatar: !!e.node.avatarImageId,
        profile: !!e.node.profileImageId,
      },
      hasMeta: {
        bio: !!e.node.bio,
        facebook: !!e.node.facebook,
        instagram: !!e.node.instagram,
        twitter: !!e.node.twitter,
        youtube: !!e.node.youtube,
      },
      ...e.node,
    })),
    total: data.totalCount,
  };
}

export async function getOneInstructor({ id }) {
  const data = await client.query(
    GET_ONE_INSTRUCTOR,
    {
      id,
    },
    "instructorById"
  );

  return { data };
}

export async function getManyInstructors(params) {
  const results = { data: [] };

  // eslint-disable-next-line no-restricted-syntax
  for (const id of params?.ids) {
    // eslint-disable-next-line no-await-in-loop
    const { data: instructor } = await getOneInstructor({ id });
    results.data.push(instructor);
  }

  return results;
}

export async function instructorUpdate(params) {
  const { data } = params;
  // if a new image was uploaded, we'll have files passed as parameters
  // upload them and use the new assetId and pass it in the update
  const avatarImageId =
    data.avatarImageUrl?.rawFile instanceof File
      ? await uploadNewAsset(data.avatarImageUrl.rawFile, "avatar", "image/png")
      : null;
  const profileImageId =
    data.profileImageUrl?.rawFile instanceof File
      ? await uploadNewAsset(
          data.profileImageUrl.rawFile,
          "profile",
          "image/jpeg"
        )
      : null;

  const res = await client.mutate(UPDATE_INSTRUCTOR, {
    input: {
      instructorId: data.id,
      slug: data.slug,
      name: data.name,
      bio: data.bio,
      credits: data.credits,
      location: data.location,
      facebook: data.facebook,
      youtube: data.youtube,
      instagram: data.instagram,
      twitter: data.twitter,
      tiktok: data.tiktok,
      ...(avatarImageId ? { avatarImageId } : {}),
      ...(profileImageId ? { profileImageId } : {}),
      featuredClassId: data?.featuredClass?.classId,
    },
  });

  const instructor = res.adminInstructorUpdate;

  return {
    data: instructor,
  };
}

export async function instructorPublish(params) {
  const { id } = params;
  const res = await client.mutate(UPDATE_INSTRUCTOR, {
    input: {
      instructorId: id,
      hidden: false,
    },
  });

  const instructor = res.adminInstructorUpdate;

  return {
    data: instructor,
  };
}

export async function instructorUnPublish(params) {
  const { id } = params;

  const res = await client.mutate(UPDATE_INSTRUCTOR, {
    input: {
      instructorId: id,
      hidden: true,
    },
  });

  const instructor = res.adminInstructorUpdate;

  return {
    data: instructor,
  };
}

async function uploadNewAsset(file, assetType, contentType) {
  const res = await client.mutate(CREATE_INSTRUCTOR_ASSET, { assetType });

  const { id, signedUrl } = res.adminInstructorAssetUploadCreate;

  await uploadAssetToS3(signedUrl, file, contentType);

  return id;
}
