export const RESOURCE_TYPE = {
  ACHIEVEMENTS: "achievements",
  BADGES: "badges",
  CATEGORIES: "categories",
  CATEGORY_VIDEOS: "categoryVideos",
  CHALLENGES: "challenges",
  CLASSES: "classes",
  CLASS_LEVELS: "classLevels",
  CLASS_STYLES: "classStyles",
  CLASS_TRACKS: "classTracks",
  CLASS_TYPES: "classTypes",
  CLASS_VIDEOS: "classVideos",
  ENUMS: "enums",
  FLAGGED_ACTIVITY: "flaggedActivity",
  INSTRUCTORS: "instructors",
  INSTRUCTOR_CREDITS: "instructorCredits",
  PLAYLISTS: "playlists",
  ROLES: "roles",
  TAGS: "tags",
  TAG_TYPES: "tagTypes",
  TRACKS: "tracks",
  TRACK_ARTISTS: "trackArtists",
  TRACK_GENRES: "trackGenres",
  USERS: "users",
  VIDEO_UPLOADS: "videoUploads",
};
