import { Badge, BadgeCreateInput, BadgeUpdateInput } from "types/graphql";
import { client } from "../gqlClient";
import { uploadAssetToS3 } from "../utils";
import {
  CREATE_BADGE,
  CREATE_BADGE_ASSET,
  GET_ONE_BADGE,
  UPDATE_BADGE,
  DELETE_BADGE,
} from "./schema";

export interface BadgeUpdateInputWithFiles extends BadgeUpdateInput {
  activeImageUrl?: { rawFile: File };
  inactiveImageUrl?: { rawFile: File };
}

export async function badgeCreate(params: {
  data: BadgeCreateInput;
}): Promise<{ data: Badge }> {
  const { data } = params;

  const res = await client.mutate(
    CREATE_BADGE,
    {
      input: data,
    },
    "adminBadgeCreate"
  );

  return {
    data: res,
  };
}

export async function getOneBadge(params: {
  id: string;
}): Promise<{ data: Badge }> {
  const { id } = params;
  const badge = await client.query(GET_ONE_BADGE, { id }, "badgeById");

  return { data: badge };
}

export async function badgeUpdate(params: {
  id: string;
  data: BadgeUpdateInputWithFiles;
}): Promise<{ data: Badge }> {
  const { id, data } = params;

  // Handle active image upload if new file provided
  const activeImageId =
    data.activeImageUrl?.rawFile instanceof File
      ? await uploadBadgeAsset(
          data.activeImageUrl.rawFile,
          "active",
          "image/svg"
        )
      : data.activeImageId;

  // Handle inactive image upload if new file provided
  const inactiveImageId =
    data.inactiveImageUrl?.rawFile instanceof File
      ? await uploadBadgeAsset(
          data.inactiveImageUrl.rawFile,
          "inactive",
          "image/svg"
        )
      : data.inactiveImageId;

  const res = await client.mutate(
    UPDATE_BADGE,
    {
      input: {
        badgeId: id,
        name: data.name,
        description: data.description,
        amountToEarn: data.amountToEarn,
        activeImageId,
        inactiveImageId,
      },
    },
    "adminBadgeUpdate"
  );

  return { data: res };
}

export async function badgeDelete(params: {
  id: string;
  previousData: Badge;
}): Promise<{ data: Badge }> {
  const { id, previousData } = params;
  await client.mutate(DELETE_BADGE, { id }, "adminBadgeDelete");
  return { data: previousData };
}

export async function uploadBadgeAsset(
  file: File,
  assetType: string,
  contentType: string
): Promise<string> {
  const res = await client.mutate(CREATE_BADGE_ASSET, {
    assetType,
  });

  const { id, signedUrl } = res.adminBadgeAssetUploadCreate;

  await uploadAssetToS3(signedUrl, file, contentType);

  return id;
}
