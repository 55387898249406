import React from "react";
import {
  CreateButton,
  Datagrid,
  EditButton,
  List,
  ListProps,
  TextField,
  TextInput,
  TopToolbar,
  Filter,
  FilterProps,
} from "react-admin";
import { DateTimeField } from "lib/components/DateTime";

const AchievementFilter = (props: Partial<FilterProps>): JSX.Element => (
  <Filter {...props}>
    <TextInput
      label="Name"
      source="name"
      alwaysOn
      style={{ minWidth: "340px" }}
    />
  </Filter>
);

const AchievementListActions = (props: any): JSX.Element => (
  <TopToolbar {...props}>
    <CreateButton />
  </TopToolbar>
);

export function AchievementList(props: ListProps): JSX.Element {
  return (
    <List
      {...props}
      perPage={30}
      bulkActionButtons={false}
      actions={<AchievementListActions />}
      filters={<AchievementFilter />}
    >
      <Datagrid>
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="triggerType" />
        <DateTimeField source="createdAt" label="Created At" />
        <DateTimeField source="updatedAt" label="Updated At" />
        <EditButton />
      </Datagrid>
    </List>
  );
}
