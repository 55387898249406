import React from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";
import { ReactSVG } from "react-svg";

const useStyles = makeStyles(() => ({
  svgWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      width: "100%",
      height: "100%",
    },
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
}));

interface SVGFromUrlProps {
  url: string;
  className?: string;
  fallback?: React.ReactNode;
  onError?: (error: unknown) => void;
}

const LoadingComponent = () => (
  <div className={useStyles().loading}>
    <CircularProgress size={20} />
  </div>
);

export const SVGFromUrl: React.FC<SVGFromUrlProps> = ({
  url,
  className,
  fallback,
  onError,
}) => {
  const classes = useStyles();
  const FallbackComponent = () => <>{fallback}</>;

  return (
    <ReactSVG
      src={url}
      className={`${classes.svgWrapper} ${className || ""}`}
      beforeInjection={(svg) => {
        svg.setAttribute("width", "100%");
        svg.setAttribute("height", "100%");
      }}
      loading={LoadingComponent}
      fallback={fallback ? FallbackComponent : undefined}
      onError={(error) => {
        onError?.(error);
      }}
    />
  );
};
