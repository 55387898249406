import {
  Achievement,
  AchievementCreateInput,
  AchievementTriggerType,
} from "types/graphql";
import {
  convertAdminPgToGraphqlRelayPg,
  convertAdminSortToGraphqlSort,
} from "data/utils";
import { client } from "../gqlClient";
import {
  CREATE_ACHIEVEMENT,
  DELETE_ACHIEVEMENT,
  GET_LIST_ACHIEVEMENTS,
  GET_ONE_ACHIEVEMENT,
  UPDATE_ACHIEVEMENT,
  REMOVE_ACHIEVEMENT_CHALLENGE,
} from "./schema";

export async function achievementCreate(params: {
  data: AchievementCreateInput;
}): Promise<{ data: Achievement }> {
  const { data } = params;

  const achievement = await client.mutate(
    CREATE_ACHIEVEMENT,
    {
      input: data,
    },
    "adminAchievementCreate"
  );

  return {
    data: achievement,
  };
}

export async function getListAchievements(params: any): Promise<{
  data: Achievement[];
  total: number;
}> {
  const { achievements } = await client.query(GET_LIST_ACHIEVEMENTS, {
    pagination: convertAdminPgToGraphqlRelayPg(params.pagination),
    sort: convertAdminSortToGraphqlSort(params.sort),
    filter: params.filter,
  });

  return {
    data: achievements.edges.map((edge: any) => edge.node),
    total: achievements.totalCount,
  };
}

export async function getManyAchievements(params: {
  ids: string[];
}): Promise<{ data: Achievement[] }> {
  const results: { data: Achievement[] } = { data: [] };

  // eslint-disable-next-line no-restricted-syntax
  for (const id of params.ids) {
    // eslint-disable-next-line no-await-in-loop
    const { data: achievement } = await getOneAchievement({ id });
    results.data.push(achievement);
  }

  return results;
}

export async function getOneAchievement(params: {
  id: string;
}): Promise<{ data: Achievement }> {
  const { id } = params;
  const achievement = await client.query(
    GET_ONE_ACHIEVEMENT,
    { id },
    "achievement"
  );

  return {
    data: achievement,
  };
}

export async function achievementUpdate(params: {
  id: string;
  data: {
    name: string;
    description: string;
    triggerType: AchievementTriggerType;
    challenge: {
      id: string;
    };
  };
}): Promise<{ data: Achievement }> {
  const { id, data } = params;

  const achievement = await client.mutate(
    UPDATE_ACHIEVEMENT,
    {
      input: {
        achievementId: id,
        name: data.name,
        description: data.description,
        triggerType: data.triggerType,
        challengeId: data.challenge?.id ?? null,
      },
    },
    "adminAchievementUpdate"
  );

  return {
    data: achievement,
  };
}

export async function achievementDelete(params: {
  id: string;
  previousData: Achievement;
}): Promise<{ data: Achievement }> {
  const { id, previousData } = params;
  await client.mutate(DELETE_ACHIEVEMENT, { id }, "adminAchievementDelete");

  return {
    data: previousData,
  };
}

export async function achievementRemoveChallenge(params: {
  id: string;
}): Promise<{ data: Achievement }> {
  const { id } = params;
  const achievement = await client.mutate(
    REMOVE_ACHIEVEMENT_CHALLENGE,
    { achievementId: id },
    "adminAchievementRemoveChallenge"
  );

  return {
    data: achievement,
  };
}
