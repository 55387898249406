import { gql } from "@apollo/client";

const CHALLENGE_FRAGMENT = gql`
  fragment ChallengeFragment on Challenge {
    id
    title
    description
    startTime
    endTime
    challengeStatus
    thumbnailImageId
    thumbnailImageUrl
    forClass {
      id
    }
    achievement {
      id
      name
      triggerType
    }
    displayText {
      earnedByHeader
      goalHeader
      subtitleHeader
    }
  }
`;

export const GET_LIST_CHALLENGES = gql`
  ${CHALLENGE_FRAGMENT}

  query AllChallenges(
    $sort: OrderByInput
    $pagination: ConnectionArgs
    $filter: ChallengeFilterInput
  ) {
    admin {
      challenges(sort: $sort, pagination: $pagination, filter: $filter) {
        totalCount
        edges {
          node {
            ...ChallengeFragment
          }
          cursor
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

export const GET_ONE_CHALLENGE = gql`
  ${CHALLENGE_FRAGMENT}
  query ChallengeById($id: String!) {
    admin {
      challenge: challengeById(id: $id) {
        ...ChallengeFragment
      }
    }
  }
`;

export const CREATE_CHALLENGE = gql`
  ${CHALLENGE_FRAGMENT}
  mutation CreateChallenge($input: ChallengeCreateInput!) {
    challengeCreate(input: $input) {
      ...ChallengeFragment
    }
  }
`;

export const UPDATE_CHALLENGE = gql`
  ${CHALLENGE_FRAGMENT}
  mutation UpdateChallenge($input: ChallengeUpdateInput!) {
    challengeUpdate(input: $input) {
      ...ChallengeFragment
    }
  }
`;

export const CREATE_CHALLENGE_ASSET = gql`
  mutation ChallengeAssetUploadCreate {
    challengeAssetUploadCreate {
      id
      signedUrl
    }
  }
`;
