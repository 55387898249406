import { client } from "../gqlClient";
import {
  convertAdminPgToGraphqlRelayPg,
  convertAdminSortToGraphqlSort,
  uploadAssetToS3,
} from "../utils";
import {
  CREATE_CHALLENGE,
  GET_LIST_CHALLENGES,
  GET_ONE_CHALLENGE,
  UPDATE_CHALLENGE,
  CREATE_CHALLENGE_ASSET,
} from "./schema";

export async function challengeCreate(params) {
  const { data } = params;

  const challenge = await client.mutate(
    CREATE_CHALLENGE,
    {
      input: {
        classId: data.forClass?.id,
        achievementId: data.achievement?.id,
        endTime: data.endTime,
        title: data.title,
        description: data.description,
        startTime: data.startTime,
        challengeStatus: data.challengeStatus,
        displayText: {
          subtitleHeader: data.displayText?.subtitleHeader,
          goalHeader: data.displayText?.goalHeader,
          earnedByHeader: data.displayText?.earnedByHeader,
        },
      },
    },
    "challengeCreate"
  );

  return {
    data: challenge,
  };
}

export async function getListChallenges(params) {
  const data = await client.query(
    GET_LIST_CHALLENGES,
    {
      filter: params.filter,
      pagination: convertAdminPgToGraphqlRelayPg(params.pagination),
      sort: convertAdminSortToGraphqlSort(params.sort),
    },
    "challenges"
  );

  return {
    data: data.edges.map((e) => e.node),
    total: data.totalCount,
  };
}

export async function getOneChallenge({ id }) {
  const data = await client.query(
    GET_ONE_CHALLENGE,
    {
      id,
    },
    "challenge"
  );

  return { data };
}

export async function challengeUpdate(params) {
  const { id, data } = params;

  let { thumbnailImageId } = data;

  if (data.thumbnailImageUrl === null) {
    thumbnailImageId = null;
  } else if (data.thumbnailImageUrl?.rawFile instanceof File) {
    thumbnailImageId = await uploadChallengeAsset(
      data.thumbnailImageUrl.rawFile,
      "thumbnail",
      "image/jpg"
    );
  }

  const challenge = await client.mutate(
    UPDATE_CHALLENGE,
    {
      input: {
        challengeId: id,
        achievementId: data.achievement?.id,
        endTime: data.endTime,
        title: data.title,
        description: data.description,
        startTime: data.startTime,
        challengeStatus: data.challengeStatus,
        thumbnailImageId,
        displayText: {
          subtitleHeader: data.displayText?.subtitleHeader,
          goalHeader: data.displayText?.goalHeader,
          earnedByHeader: data.displayText?.earnedByHeader,
        },
      },
    },
    "challengeUpdate"
  );

  return {
    data: challenge,
  };
}

export async function getManyChallenges(params) {
  const results = { data: [] };

  // eslint-disable-next-line no-restricted-syntax
  for (const id of params?.ids) {
    // eslint-disable-next-line no-await-in-loop
    const { data: challenge } = await getOneChallenge({ id });
    results.data.push(challenge);
  }

  return results;
}

export async function uploadChallengeAsset(file, contentType) {
  const res = await client.mutate(CREATE_CHALLENGE_ASSET);

  const { id, signedUrl } = res.challengeAssetUploadCreate;

  await uploadAssetToS3(signedUrl, file, contentType);

  return id;
}
