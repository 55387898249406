import React from "react";
import { Resource } from "react-admin";
import { RESOURCE_TYPE as RT } from "lib/constants/resourceType";

import {
  AchievementCreate,
  AchievementEdit,
  AchievementIcon,
  AchievementList,
} from "./achievements";
import {
  CategoryCreate,
  CategoryEdit,
  CategoryIcon,
  CategoryList,
} from "./categories";
import {
  ChallengeCreate,
  ChallengeEdit,
  ChallengeIcon,
  ChallengeList,
} from "./challenges";
import {
  VideoUploadEdit,
  VideoUploadIcon,
  VideoUploadList,
} from "./videoUploads";
import { ClassCreate, ClassEdit, ClassIcon, ClassList } from "./classes";
import {
  FlaggedActivityList,
  FlaggedActivityReportIcon,
  FlaggedActivityCreate,
  FlaggedActivityEdit,
} from "./flaggedActivity";
import {
  InstructorCreate,
  InstructorEdit,
  InstructorIcon,
  InstructorList,
} from "./instructors";
import { TrackCreate, TrackEdit, TrackIcon, TrackList } from "./tracks";
import { TagCreate, TagEdit, TagIcon, TagList } from "./tags";
import { UserEdit, UserIcon, UserList } from "./users";
import {
  PlaylistCreate,
  PlaylistEdit,
  PlaylistIcon,
  PlaylistList,
} from "./playlists";

export const Resources = [
  <Resource
    name={RT.ACHIEVEMENTS}
    create={AchievementCreate}
    edit={AchievementEdit}
    icon={AchievementIcon}
    list={AchievementList}
  />,
  <Resource name={RT.BADGES} />,
  <Resource
    name={RT.CATEGORIES}
    create={CategoryCreate}
    edit={CategoryEdit}
    icon={CategoryIcon}
    list={CategoryList}
  />,
  <Resource
    name={RT.CHALLENGES}
    create={ChallengeCreate}
    edit={ChallengeEdit}
    icon={ChallengeIcon}
    list={ChallengeList}
  />,
  <Resource
    name={RT.CLASSES}
    create={ClassCreate}
    edit={ClassEdit}
    icon={ClassIcon}
    list={ClassList}
  />,
  <Resource
    name={RT.FLAGGED_ACTIVITY}
    create={FlaggedActivityCreate}
    edit={FlaggedActivityEdit}
    icon={FlaggedActivityReportIcon}
    list={FlaggedActivityList}
    options={{ label: "Flagged Content" }}
  />,
  <Resource
    name={RT.INSTRUCTORS}
    create={InstructorCreate}
    edit={InstructorEdit}
    icon={InstructorIcon}
    list={InstructorList}
  />,
  <Resource
    name={RT.PLAYLISTS}
    create={PlaylistCreate}
    edit={PlaylistEdit}
    icon={PlaylistIcon}
    list={PlaylistList}
  />,
  <Resource
    name={RT.TRACKS}
    create={TrackCreate}
    edit={TrackEdit}
    icon={TrackIcon}
    list={TrackList}
  />,
  <Resource
    name={RT.TAGS}
    create={TagCreate}
    edit={TagEdit}
    icon={TagIcon}
    list={TagList}
  />,
  <Resource name={RT.USERS} edit={UserEdit} icon={UserIcon} list={UserList} />,
  <Resource
    name={RT.VIDEO_UPLOADS}
    edit={VideoUploadEdit}
    icon={VideoUploadIcon}
    list={VideoUploadList}
    options={{ label: "User Videos" }}
  />,
  <Resource name={RT.CATEGORY_VIDEOS} />,
  <Resource name={RT.CLASS_LEVELS} />,
  <Resource name={RT.CLASS_STYLES} />,
  <Resource name={RT.CLASS_TRACKS} />,
  <Resource name={RT.CLASS_TYPES} />,
  <Resource name={RT.CLASS_VIDEOS} />,
  <Resource name={RT.ENUMS} />,
  <Resource name={RT.ROLES} />,
  <Resource name={RT.TAG_TYPES} />,
];
