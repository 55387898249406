import * as React from "react";
import {
  Typography,
  Box,
  Card,
  CardContent,
  IconButton,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import EditIcon from "@material-ui/icons/Edit";
import { useRecordContext, ReferenceInput, SelectInput } from "react-admin";

interface ChallengeRecord {
  achievement?: {
    id: string;
    name: string;
    triggerType: string;
  };
}

export const AchievementCard = () => {
  const record = useRecordContext<ChallengeRecord>();
  const achievement = record?.achievement;
  const [isEditing, setIsEditing] = React.useState(!record?.achievement);

  const renderEditMode = () => (
    <Card>
      <CardContent>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h6">Achievement</Typography>
          {achievement && (
            <IconButton
              component="a"
              href={`#/achievements/${achievement.id}/edit`}
              size="small"
              title="Go to Achievement"
            >
              <OpenInNewIcon color="primary" />
            </IconButton>
          )}
        </Box>
        <ReferenceInput
          source="achievement.id"
          reference="achievements"
          label="Achievement"
          fullWidth
        >
          <SelectInput
            optionText={(record) => `${record.name} (${record.triggerType})`}
          />
        </ReferenceInput>
      </CardContent>
    </Card>
  );

  const renderViewMode = () => (
    <Card>
      <CardContent>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h6">Achievement</Typography>
          <Box>
            <IconButton
              onClick={() => setIsEditing(true)}
              size="small"
              title="Edit Achievement"
            >
              <EditIcon color="action" />
            </IconButton>
            {achievement && (
              <IconButton
                component="a"
                href={`#/achievements/${achievement.id}/edit`}
                size="small"
                title="Go to Achievement"
              >
                <OpenInNewIcon color="primary" />
              </IconButton>
            )}
          </Box>
        </Box>
        {achievement && (
          <Box mt={2}>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              Name
            </Typography>
            <Typography>{achievement.name}</Typography>
            <Box mt={3}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                Trigger Type
              </Typography>
              <Typography>{achievement.triggerType}</Typography>
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );

  return isEditing ? renderEditMode() : renderViewMode();
};
