import * as React from "react";
import {
  Datagrid,
  EditButton,
  FilterList,
  FilterListItem,
  FunctionField,
  Link,
  List,
  TextField,
  TextInput,
  ListProps,
} from "react-admin";
import { DateTimeField } from "lib/components/DateTime";
import { CardContent } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { FilterSidebar } from "lib/components/FilterSidebar";
import { HelpInfo } from "lib/components/HelpInfo";
import { TimeFilterOptionsList } from "lib/components/TimeFilterOptionsList";
import { useEnums } from "lib/hooks";
import { useChallengeListStyles } from "./styles";

interface Challenge {
  id: string;
  title: string;
  startTime: string;
  endTime: string;
  challengeStatus: string;
}

const challengeFilters = [
  <TextInput
    label="Class ID"
    source="classId"
    alwaysOn
    style={{ minWidth: "340px" }}
  />,
];

const ChallengesFilterSidebar = () => {
  const classes = useChallengeListStyles();
  const { enums } = useEnums();

  return (
    <FilterSidebar>
      <CardContent className={classes.filterCard}>
        <div className={classes.helpButton}>
          <HelpInfo
            helpInfos={[
              ["Filters"],
              [
                "Active",
                "Active indicates the time between the start and end times for the challenge.",
              ],
              [
                "Status",
                "None is when the challenge is not promoted and hidden. Approved is when the challenge is promoted and visible. Deleted is when the challenge is soft deleted and hidden.",
              ],
            ]}
          />
        </div>
        <TimeFilterOptionsList label="Active" filterFieldPrefix="active" />
        <FilterList label="Status" icon={<FilterListIcon />}>
          {enums?.challengeStatus?.map((challengeStatus: string) => (
            <FilterListItem
              label={challengeStatus}
              key={challengeStatus}
              value={{ challengeStatus }}
            />
          ))}
        </FilterList>
      </CardContent>
    </FilterSidebar>
  );
};

export function ChallengeList(props: ListProps) {
  const classes = useChallengeListStyles();
  return (
    <List
      {...props}
      perPage={10}
      sort={{ field: "startTime", order: "DESC" }}
      bulkActionButtons={false}
      filters={challengeFilters}
      aside={<ChallengesFilterSidebar />}
    >
      <Datagrid>
        <TextField source="title" sortable={false} />
        <DateTimeField source="startTime" label="Start Time" />
        <DateTimeField source="endTime" label="End Time" />
        <TextField label="Status" source="challengeStatus" sortable={false} />
        <FunctionField<Challenge>
          render={(record) => {
            if (!record?.id) {
              return null;
            }
            return (
              <Link
                className={classes.link}
                to={`/videoUploads?filter={"challengeId":"${record.id}"}`}
              >
                Videos
              </Link>
            );
          }}
        />
        <EditButton className={classes.editButton} basePath="/challenges" />
      </Datagrid>
    </List>
  );
}
