export enum ChallengeHelpItem {
  CREATE_TITLE = "CREATE_TITLE",
  EDIT_TITLE = "EDIT_TITLE",
  CLASS_ID = "CLASS_ID",
  TITLE = "TITLE",
  DESCRIPTION = "DESCRIPTION",
  STATUS = "STATUS",
  START_TIME = "START_TIME",
  END_TIME = "END_TIME",
  THUMBNAIL = "THUMBNAIL",
  ACHIEVEMENT = "ACHIEVEMENT",
  SUBTITLE_HEADER = "SUBTITLE_HEADER",
  GOAL_HEADER = "GOAL_HEADER",
  EARNED_BY_HEADER = "EARNED_BY_HEADER",
}

type HelpInfo = [string, string];

const HELP_INFO_MAP: Record<ChallengeHelpItem, HelpInfo> = {
  [ChallengeHelpItem.CREATE_TITLE]: [
    "Create Challenge",
    "Create a new challenge for users to participate in",
  ],
  [ChallengeHelpItem.EDIT_TITLE]: [
    "Edit Challenge",
    "Modify an existing challenge's details",
  ],
  [ChallengeHelpItem.CLASS_ID]: [
    "(DEPRECATED) Class ID",
    "Enter in the class ID number to be associated with this challenge. Ex: 1583",
  ],
  [ChallengeHelpItem.TITLE]: [
    "Title",
    "Enter in the title for the challenge. Maximum 255 characters.",
  ],
  [ChallengeHelpItem.DESCRIPTION]: [
    "Description",
    "Enter in the description for the challenge. Maximum 255 characters.",
  ],
  [ChallengeHelpItem.STATUS]: [
    "Status",
    "None is when the challenge is not promoted and hidden. Approved is when the challenge is promoted and visible. Deleted is when the challenge is soft deleted and hidden.",
  ],
  [ChallengeHelpItem.START_TIME]: [
    "Start Time",
    "Set the date and time when the challenge should start being visible to users. This is in your local time zone.",
  ],
  [ChallengeHelpItem.END_TIME]: [
    "End Time",
    "Set the date and time when the challenge should stop being visible to users. This is in your local time zone.",
  ],
  [ChallengeHelpItem.THUMBNAIL]: [
    "Thumbnail (must be 1920x1080 aspect ratio, .jpg)",
    "Upload a thumbnail image for the challenge. This will be displayed in the challenge list and details views.",
  ],
  [ChallengeHelpItem.ACHIEVEMENT]: [
    "Achievement",
    "Select an achievement to associate with this challenge. The achievement will be awarded to users who complete the challenge.",
  ],
  [ChallengeHelpItem.SUBTITLE_HEADER]: [
    "Subtitle Header",
    "Enter the subtitle text that appears at the top of the challenge details right below the title. This helps provide context about the challenge.",
  ],
  [ChallengeHelpItem.GOAL_HEADER]: [
    "Goal Header",
    "Enter the text that describes the goal of the challenge. This tells users what they need to do to complete the challenge.",
  ],
  [ChallengeHelpItem.EARNED_BY_HEADER]: [
    "Earned By Header",
    "Enter the text that describes how a user earns an award. This is displayed in the challenge details view.",
  ],
};

export const compileHelpInfo = (items: ChallengeHelpItem[]): HelpInfo[] =>
  items.map((item) => HELP_INFO_MAP[item]);
