import * as React from "react";
import { Typography, Box, InputAdornment } from "@material-ui/core";
import {
  Edit,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  ImageInput,
  FunctionField,
  useRecordContext,
} from "react-admin";
import { useEnums } from "lib/hooks";
import { DateTimeInput } from "lib/components/DateTime";
import { ReadOnlyField } from "lib/components/ReadOnlyField";
import { HelpInfo } from "lib/components/HelpInfo";
import PlaceholderImg from "lib/assets/placeholder-image.png";
import SubjectIcon from "@material-ui/icons/Subject";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import { compileHelpInfo, ChallengeHelpItem } from "./helpInfo";
import { validateChallenge } from "./utils";
import { AchievementCard } from "./components/AchievementCard";

const EDIT_HELP_ITEMS: ChallengeHelpItem[] = [
  ChallengeHelpItem.EDIT_TITLE,
  ChallengeHelpItem.CLASS_ID,
  ChallengeHelpItem.TITLE,
  ChallengeHelpItem.DESCRIPTION,
  ChallengeHelpItem.STATUS,
  ChallengeHelpItem.START_TIME,
  ChallengeHelpItem.END_TIME,
  ChallengeHelpItem.THUMBNAIL,
  ChallengeHelpItem.ACHIEVEMENT,
  ChallengeHelpItem.SUBTITLE_HEADER,
  ChallengeHelpItem.GOAL_HEADER,
  ChallengeHelpItem.EARNED_BY_HEADER,
];

interface ChallengeRecord {
  achievement?: {
    id: string;
    name: string;
    triggerType: string;
  };
  thumbnailImageId?: string;
}

interface ThumbnailPreviewProps {
  imageRecord:
    | {
        rawFile?: File;
        src?: string;
      }
    | string;
}

const ThumbnailPreview: React.FC<ThumbnailPreviewProps> = ({ imageRecord }) => {
  const record = useRecordContext<ChallengeRecord>();
  if (!record?.thumbnailImageId && typeof imageRecord !== "object") {
    return null;
  }

  const url =
    typeof imageRecord === "object" && imageRecord.rawFile
      ? URL.createObjectURL(imageRecord.rawFile)
      : `${imageRecord}?w=300`;
  return (
    <img
      alt="thumbnail"
      src={url}
      width={300}
      onError={(e) => {
        const target = e.target as HTMLImageElement;
        target.src = PlaceholderImg;
      }}
    />
  );
};

interface ChallengeEditProps {
  [key: string]: any;
}

export const ChallengeEdit: React.FC<ChallengeEditProps> = (props) => {
  const { enums } = useEnums();

  return (
    <Edit {...props} mutationMode="pessimistic">
      <SimpleForm
        toolbar={<ChallengeEditToolbar />}
        validate={validateChallenge}
        warnWhenUnsavedChanges
      >
        <Typography
          variant="h6"
          style={{ display: "flex", alignItems: "center" }}
        >
          Edit Challenge
          <HelpInfo helpInfos={compileHelpInfo(EDIT_HELP_ITEMS)} />
        </Typography>
        <ReadOnlyField addLabel label="ID" source="id" />
        <ReadOnlyField addLabel label="Class ID" source="forClass.id" />
        <TextInput source="title" fullWidth />
        <TextInput source="description" fullWidth multiline />
        {enums?.challengeStatus && (
          <SelectInput
            source="challengeStatus"
            choices={enums.challengeStatus.map((status: string) => ({
              id: status,
              name: status,
            }))}
            fullWidth
          />
        )}
        <Box style={{ display: "flex", gap: 16, marginTop: 16 }}>
          <Box style={{ flex: 1 }}>
            <DateTimeInput label="Start Time" source="startTime" />
          </Box>
          <Box style={{ flex: 1 }}>
            <DateTimeInput label="End Time" source="endTime" />
          </Box>
        </Box>
        <Typography variant="subtitle1" style={{ marginTop: 16 }}>
          Display Text
        </Typography>
        <TextInput
          source="displayText.subtitleHeader"
          label="Subtitle Header"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SubjectIcon color="action" />
              </InputAdornment>
            ),
          }}
        />
        <TextInput
          source="displayText.goalHeader"
          label="Goal Header"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CheckCircleIcon color="action" />
              </InputAdornment>
            ),
          }}
        />
        <TextInput
          source="displayText.earnedByHeader"
          label="Earned By Header"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmojiEventsIcon color="action" />
              </InputAdornment>
            ),
          }}
        />
        <Box mt={2}>
          <ImageInput
            source="thumbnailImageUrl"
            label="Challenge Thumbnail (.jpg)"
            accept="image/jpeg"
            multiple={false}
          >
            <FunctionField<any>
              render={(record: any) => (
                <ThumbnailPreview imageRecord={record} />
              )}
            />
          </ImageInput>
        </Box>
        <AchievementCard />
      </SimpleForm>
    </Edit>
  );
};

interface ToolbarProps {
  [key: string]: any;
}

function ChallengeEditToolbar(props: ToolbarProps) {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
}
