import { RESOURCE_TYPE as RT } from "lib/constants/resourceType";
import {
  achievementCreate,
  achievementUpdate,
  achievementDelete,
  getListAchievements,
  getOneAchievement,
  achievementRemoveChallenge,
  getManyAchievements,
} from "./achievements";
import { badgeCreate, badgeUpdate, getOneBadge, badgeDelete } from "./badges";
import {
  categoriesBulkCreate,
  categoryCreate,
  categoryPublish,
  categoryUnPublish,
  categoryUpdate,
  getListCategories,
  getManyCategories,
  getOneCategory,
} from "./categories";
import { getManyReferenceCategoryVideos } from "./categoryVideos";
import {
  challengeCreate,
  challengeUpdate,
  getListChallenges,
  getOneChallenge,
  getManyChallenges,
} from "./challenges";
import { getManyReferenceClassVideos } from "./classVideos";
import {
  addClassTrack,
  classCreate,
  classPublish,
  classUnPublish,
  classUpdate,
  getClassLevels,
  getClassStyles,
  getClassTypes,
  getListClasses,
  getManyClasses,
  getManyReferenceClasses,
  getOneClass,
  removeClassTrack,
} from "./classes";
import { getEnums } from "./enums";
import {
  flaggedActivities,
  flaggedActivityCreate,
  flaggedActivityReview,
  getOneFlaggedActivity,
} from "./flaggedActivity";
import {
  getAllInstructorCredits,
  getListInstructors,
  getManyInstructors,
  getOneInstructor,
  instructorBulkCreate,
  instructorCreate,
  instructorPublish,
  instructorUnPublish,
  instructorUpdate,
} from "./instructors";
import {
  playlistCreate,
  playlistPublish,
  playlistUnPublish,
  playlistUpdate,
  getListPlaylists,
  getOnePlaylist,
  playlistAddClass,
  playlistRemoveClass,
} from "./playlists";
import { getListRoles, getManyRoles } from "./roles";
import {
  getAllTrackArtists,
  getAllTrackGenres,
  getListTracks,
  getManyReferenceTracks,
  getOneTrack,
  trackBulkUpsert,
  trackUpsert,
  updateTrackLicense,
} from "./tracks";
import {
  createTag,
  getListTags,
  getOneTag,
  getTagTypes,
  updateTag,
} from "./tags";
import {
  bulkEnableCommunity,
  getListUsers,
  getOneUser,
  updateUser,
} from "./users";
import {
  getListVideoUploads,
  getOneVideoUpload,
  videoUploadUpdate,
} from "./videoUploads";

/**
 * Query a data provider and return a promise for a response
 *
 * @example
 * dataProvider(GET_ONE, 'posts', { id: 123 })
 *  => Promise.resolve({ data: { id: 123, title: "hello, world" } })
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the action type
 * @returns {Promise} the Promise for a response
 */
export const dataProvider = (type, resource, params) => {
  switch (`${resource}-${type}`) {
    case `${RT.ACHIEVEMENTS}-CREATE`:
      return achievementCreate(params);
    case `${RT.ACHIEVEMENTS}-GET_LIST`:
      return getListAchievements(params);
    case `${RT.ACHIEVEMENTS}-GET_ONE`:
      return getOneAchievement(params);
    case `${RT.ACHIEVEMENTS}-UPDATE`:
      return achievementUpdate(params);
    case `${RT.ACHIEVEMENTS}-DELETE`:
      return achievementDelete(params);
    case `${RT.ACHIEVEMENTS}-GET_MANY`:
      return getManyAchievements({ ids: params.ids });
    case `${RT.ACHIEVEMENTS}-GET_MANY_REFERENCE`:
      return getListAchievements({
        ...params,
        filter: { [params.target]: params.id },
      });
    case `${RT.ACHIEVEMENTS}-removeChallenge`:
      return achievementRemoveChallenge(params);

    case `${RT.CATEGORIES}-CREATE`:
      return categoryCreate(params);
    case `${RT.CATEGORIES}-GET_LIST`:
      return getListCategories(params);
    case `${RT.CATEGORIES}-GET_ONE`:
      return getOneCategory(params);
    case `${RT.CATEGORIES}-GET_MANY`:
      return getManyCategories(params);
    case `${RT.CATEGORIES}-UPDATE`:
      return categoryUpdate(params);
    case `${RT.CATEGORIES}-createMany`:
      return categoriesBulkCreate(params);
    case `${RT.CATEGORIES}-publish`:
      return categoryPublish(params);
    case `${RT.CATEGORIES}-unPublish`:
      return categoryUnPublish(params);

    case `${RT.CATEGORY_VIDEOS}-GET_MANY_REFERENCE`:
      return getManyReferenceCategoryVideos(params);

    case `${RT.CHALLENGES}-CREATE`:
      return challengeCreate(params);
    case `${RT.CHALLENGES}-GET_LIST`:
      return getListChallenges(params);
    case `${RT.CHALLENGES}-GET_ONE`:
      return getOneChallenge(params);
    case `${RT.CHALLENGES}-UPDATE`:
      return challengeUpdate(params);
    case `${RT.CHALLENGES}-GET_MANY`:
      return getManyChallenges(params);
    case `${RT.CHALLENGES}-GET_MANY_REFERENCE`:
      return getListChallenges({
        ...params,
        filter: { [params.target]: params.id },
      });

    case `${RT.CLASSES}-CREATE`:
      return classCreate(params);
    case `${RT.CLASSES}-GET_LIST`:
      return getListClasses(params);
    case `${RT.CLASSES}-GET_MANY_REFERENCE`:
      return getManyReferenceClasses(params);
    case `${RT.CLASSES}-GET_ONE`:
      return getOneClass(params);
    case `${RT.CLASSES}-GET_MANY`:
      return getManyClasses(params);
    case `${RT.CLASSES}-UPDATE`:
      return classUpdate(params);
    case `${RT.CLASSES}-publish`:
      return classPublish(params);
    case `${RT.CLASSES}-unPublish`:
      return classUnPublish(params);

    case `${RT.CLASS_LEVELS}-GET_LIST`:
      return getClassLevels();
    case `${RT.CLASS_LEVELS}-GET_MANY`:
      return getClassLevels();

    case `${RT.CLASS_STYLES}-GET_LIST`:
      return getClassStyles();
    case `${RT.CLASS_STYLES}-GET_MANY`:
      return getClassStyles();

    case `${RT.CLASS_TRACKS}-CREATE`:
      return addClassTrack(params);
    case `${RT.CLASS_TRACKS}-DELETE`:
      return removeClassTrack(params);

    case `${RT.CLASS_TYPES}-GET_LIST`:
      return getClassTypes();
    case `${RT.CLASS_TYPES}-GET_MANY`:
      return getClassTypes();

    case `${RT.CLASS_VIDEOS}-GET_MANY_REFERENCE`:
      return getManyReferenceClassVideos(params);

    case `${RT.ENUMS}-GET_ONE`:
      return getEnums(params);

    case `${RT.FLAGGED_ACTIVITY}-CREATE`: // not normal crud action
      return flaggedActivityCreate(params);
    case `${RT.FLAGGED_ACTIVITY}-GET_LIST`:
      return flaggedActivities(params);
    case `${RT.FLAGGED_ACTIVITY}-GET_ONE`:
      return getOneFlaggedActivity(params);
    case `${RT.FLAGGED_ACTIVITY}-UPDATE`: // not normal crud action
      return flaggedActivityReview(params);

    case `${RT.INSTRUCTORS}-CREATE`:
      return instructorCreate(params);
    case `${RT.INSTRUCTORS}-GET_LIST`:
      return getListInstructors(params);
    case `${RT.INSTRUCTORS}-GET_ONE`:
      return getOneInstructor(params);
    case `${RT.INSTRUCTORS}-GET_MANY`:
      return getManyInstructors(params);
    case `${RT.INSTRUCTORS}-UPDATE`:
      return instructorUpdate(params);
    case `${RT.INSTRUCTORS}-createMany`:
      return instructorBulkCreate(params);
    case `${RT.INSTRUCTORS}-publish`:
      return instructorPublish(params);
    case `${RT.INSTRUCTORS}-unPublish`:
      return instructorUnPublish(params);

    case `${RT.INSTRUCTOR_CREDITS}-GET_LIST`:
      return getAllInstructorCredits();

    case `${RT.PLAYLISTS}-CREATE`:
      return playlistCreate(params);
    case `${RT.PLAYLISTS}-GET_LIST`:
      return getListPlaylists(params);
    case `${RT.PLAYLISTS}-GET_ONE`:
      return getOnePlaylist(params);
    case `${RT.PLAYLISTS}-UPDATE`:
      return playlistUpdate(params);
    case `${RT.PLAYLISTS}-publish`:
      return playlistPublish(params);
    case `${RT.PLAYLISTS}-unPublish`:
      return playlistUnPublish(params);
    case `${RT.PLAYLISTS}-addClass`:
      return playlistAddClass(params);
    case `${RT.PLAYLISTS}-removeClass`:
      return playlistRemoveClass(params);

    case `${RT.ROLES}-GET_LIST`:
      return getListRoles(params);
    case `${RT.ROLES}-GET_MANY`:
      return getManyRoles(params);

    case `${RT.TAGS}-CREATE`:
      return createTag(params);
    case `${RT.TAGS}-UPDATE`:
      return updateTag(params);
    case `${RT.TAGS}-GET_LIST`:
      return getListTags(params);
    case `${RT.TAGS}-GET_ONE`:
      return getOneTag(params);

    case `${RT.TAG_TYPES}-GET_LIST`:
      return getTagTypes();
    case `${RT.TAG_TYPES}-GET_MANY`:
      return getTagTypes();

    case `${RT.TRACKS}-CREATE`:
    case `${RT.TRACKS}-UPDATE`:
      return trackUpsert(params);
    case `${RT.TRACKS}-GET_LIST`:
      return getListTracks(params);
    case `${RT.TRACKS}-GET_MANY_REFERENCE`:
      return getManyReferenceTracks(params);
    case `${RT.TRACKS}-GET_ONE`:
      return getOneTrack(params);
    case `${RT.TRACKS}-createMany`:
      return trackBulkUpsert(params);
    case `${RT.TRACKS}-updateLicense`:
      return updateTrackLicense(params);

    case `${RT.TRACK_ARTISTS}-GET_LIST`:
      return getAllTrackArtists();

    case `${RT.TRACK_GENRES}-GET_LIST`:
      return getAllTrackGenres();

    case `${RT.USERS}-GET_LIST`:
      return getListUsers(params);
    case `${RT.USERS}-GET_ONE`:
      return getOneUser(params);
    case `${RT.USERS}-UPDATE`:
      return updateUser(params);
    case `${RT.USERS}-createMany`:
      return bulkEnableCommunity(params);

    case `${RT.VIDEO_UPLOADS}-GET_LIST`:
      return getListVideoUploads(params);
    case `${RT.VIDEO_UPLOADS}-UPDATE`:
      return videoUploadUpdate(params);
    case `${RT.VIDEO_UPLOADS}-GET_ONE`:
      return getOneVideoUpload(params);

    case `${RT.BADGES}-CREATE`:
      return badgeCreate(params);
    case `${RT.BADGES}-GET_ONE`:
      return getOneBadge(params);
    case `${RT.BADGES}-UPDATE`:
      return badgeUpdate(params);
    case `${RT.BADGES}-DELETE`:
      return badgeDelete(params);

    default:
      // eslint-disable-next-line no-console
      console.warn("Unrecognized data request for ", type, resource, params);
      return null;
  }
};
