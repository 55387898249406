import { Typography, InputAdornment } from "@material-ui/core";
import React from "react";
import { Create, SelectInput, SimpleForm, TextInput } from "react-admin";
import { withEnums } from "data/utils";
import { HelpInfo } from "lib/components/HelpInfo";
import { DateTimeInput } from "lib/components/DateTime";
import SubjectIcon from "@material-ui/icons/Subject";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import { validateChallenge } from "./utils";
import { compileHelpInfo, ChallengeHelpItem } from "./helpInfo";

const CREATE_HELP_ITEMS: ChallengeHelpItem[] = [
  ChallengeHelpItem.CREATE_TITLE,
  ChallengeHelpItem.TITLE,
  ChallengeHelpItem.DESCRIPTION,
  ChallengeHelpItem.STATUS,
  ChallengeHelpItem.START_TIME,
  ChallengeHelpItem.END_TIME,
  ChallengeHelpItem.SUBTITLE_HEADER,
  ChallengeHelpItem.GOAL_HEADER,
  ChallengeHelpItem.EARNED_BY_HEADER,
];

interface ChallengeCreateProps {
  enums: {
    challengeStatus: string[];
  };
}

export const ChallengeCreate = withEnums(
  ({ enums, ...props }: ChallengeCreateProps) => (
    <Create {...props}>
      <SimpleForm validate={validateChallenge}>
        <Typography
          variant="h6"
          style={{ display: "flex", alignItems: "center" }}
        >
          Create Challenge
          <HelpInfo helpInfos={compileHelpInfo(CREATE_HELP_ITEMS)} />
        </Typography>
        {/* Comment out for now because we don't to allow this anymore/yet */}
        {/* <TextInput label="Class ID" source="forClass.id" fullWidth /> */}
        <TextInput source="title" fullWidth />
        <TextInput source="description" fullWidth multiline />
        <Typography variant="subtitle1" style={{ marginTop: 16 }}>
          Display Text
        </Typography>
        <TextInput
          source="displayText.subtitleHeader"
          label="Subtitle Header"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SubjectIcon color="action" />
              </InputAdornment>
            ),
          }}
        />
        <TextInput
          source="displayText.goalHeader"
          label="Goal Header"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CheckCircleIcon color="action" />
              </InputAdornment>
            ),
          }}
        />
        <TextInput
          source="displayText.earnedByHeader"
          label="Earned By Header"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmojiEventsIcon color="action" />
              </InputAdornment>
            ),
          }}
        />
        <SelectInput
          source="challengeStatus"
          choices={enums.challengeStatus.map((status) => ({
            id: status,
            name: status,
          }))}
        />
        <DateTimeInput label="Start Time" source="startTime" />
        <DateTimeInput label="End Time" source="endTime" />
      </SimpleForm>
    </Create>
  )
);
