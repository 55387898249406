import React from "react";
import {
  Create,
  CreateProps,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  SaveButton,
  Toolbar,
} from "react-admin";
import { useEnums } from "lib/hooks";
import { Typography } from "@material-ui/core";
import { HelpInfo } from "lib/components/HelpInfo";
import { compileHelpInfo, AchievementHelpItem } from "../helpInfo";

const CREATE_HELP_ITEMS: AchievementHelpItem[] = [
  AchievementHelpItem.CREATE_TITLE,
  AchievementHelpItem.NAME,
  AchievementHelpItem.DESCRIPTION,
  AchievementHelpItem.TRIGGER_TYPE,
];

function AchievementCreateToolbar(props: any) {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
}

export function AchievementCreate(props: CreateProps): JSX.Element {
  const { enums } = useEnums();

  return (
    <Create {...props}>
      <SimpleForm toolbar={<AchievementCreateToolbar />}>
        <Typography
          variant="h6"
          style={{ display: "flex", alignItems: "center" }}
        >
          Create Achievement
          <HelpInfo helpInfos={compileHelpInfo(CREATE_HELP_ITEMS)} />
        </Typography>
        <TextInput
          source="name"
          validate={[required()]}
          style={{ width: "100%" }}
        />
        <TextInput source="description" multiline style={{ width: "100%" }} />
        <SelectInput
          source="triggerType"
          choices={enums?.achievementTriggerType?.map((type: string) => ({
            id: type,
            name: type,
          }))}
          validate={[required()]}
          style={{ width: "100%" }}
        />
      </SimpleForm>
    </Create>
  );
}
