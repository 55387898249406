import { gql } from "@apollo/client";

export const BADGE_FRAGMENT = gql`
  fragment BadgeFields on Badge {
    id
    name
    description
    amountToEarn
    activeImageId
    activeImageUrl
    inactiveImageId
    inactiveImageUrl
    createdAt
    updatedAt
  }
`;

export const CREATE_BADGE = gql`
  ${BADGE_FRAGMENT}
  mutation CreateBadge($input: BadgeCreateInput!) {
    adminBadgeCreate(input: $input) {
      ...BadgeFields
    }
  }
`;

export const GET_ONE_BADGE = gql`
  ${BADGE_FRAGMENT}
  query GetBadge($id: String!) {
    badgeById(id: $id) {
      ...BadgeFields
    }
  }
`;

export const UPDATE_BADGE = gql`
  ${BADGE_FRAGMENT}
  mutation UpdateBadge($input: BadgeUpdateInput!) {
    adminBadgeUpdate(input: $input) {
      ...BadgeFields
    }
  }
`;

export const DELETE_BADGE = gql`
  mutation DeleteBadge($id: String!) {
    adminBadgeDelete(id: $id)
  }
`;

export const CREATE_BADGE_ASSET = gql`
  mutation BadgeAssetUploadCreate($assetType: BadgeAssetType!) {
    adminBadgeAssetUploadCreate(assetType: $assetType) {
      id
      signedUrl
    }
  }
`;
