import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import { useRecordContext } from "react-admin";
import { Typography } from "@material-ui/core";

export function ReadOnlyField({
  source,
  ...props
}: {
  source: string;
  [key: string]: any;
}) {
  const record = useRecordContext(props);

  if (!record) {
    return null;
  }

  const value = get(record, source);

  if (!value) {
    return <>None</>;
  }

  return <Typography>{value}</Typography>;
}

ReadOnlyField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
};
