import React from "react";
import { Button } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import {
  useNotify,
  useRecordContext,
  useRefresh,
  useDataProvider,
} from "react-admin";
import { Badge } from "types/graphql";

export function RemoveBadgeField(): JSX.Element {
  const record = useRecordContext<Badge>();
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const handleClick = async () => {
    if (!window.confirm("Are you sure you want to remove this badge?")) {
      return;
    }

    try {
      await dataProvider.delete("badges", {
        id: record.id,
        previousData: record,
      });
      notify("Badge removed successfully");
      refresh();
    } catch (error) {
      notify("Error: Could not remove badge");
    }
  };

  return (
    <Button
      onClick={handleClick}
      size="small"
      color="secondary"
      variant="text"
      startIcon={<DeleteIcon />}
    >
      Delete Badge
    </Button>
  );
}
