import React, { useState } from "react";
import { Dialog, DialogTitle, IconButton, makeStyles } from "@material-ui/core";
import { Add as AddIcon, Close as CloseIcon } from "@material-ui/icons";
import {
  NumberInput,
  SimpleForm,
  TextInput,
  required,
  Create,
  useRefresh,
} from "react-admin";

const useStyles = makeStyles({
  addButton: {
    marginLeft: "1rem",
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  dialogTitle: {
    paddingRight: "48px",
  },
});

interface AddBadgeProps {
  achievementId: string;
}

export function AddBadge({ achievementId }: AddBadgeProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const refresh = useRefresh();

  const handleSuccess = () => {
    setOpen(false);
    refresh();
  };

  return (
    <>
      <IconButton
        className={classes.addButton}
        onClick={() => setOpen(true)}
        color="primary"
      >
        <AddIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className={classes.dialogTitle}>
          Add Badge
          <IconButton
            className={classes.closeButton}
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Create resource="badges" basePath="/badges" onSuccess={handleSuccess}>
          <SimpleForm>
            <TextInput
              source="name"
              validate={[required()]}
              style={{ width: "100%" }}
            />
            <TextInput
              source="description"
              multiline
              style={{ width: "100%" }}
            />
            <NumberInput
              source="amountToEarn"
              validate={[required()]}
              style={{ width: "100%" }}
            />
            <TextInput
              source="achievementId"
              defaultValue={achievementId}
              style={{ display: "none" }}
            />
          </SimpleForm>
        </Create>
      </Dialog>
    </>
  );
}
