import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  Box,
  Button,
} from "@material-ui/core";
import { Edit as EditIcon, Close as CloseIcon } from "@material-ui/icons";
import {
  NumberInput,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
  ImageInput,
  FunctionField,
  Edit,
  SaveButton,
  Toolbar,
  ToolbarProps,
  useRefresh,
} from "react-admin";
import PlaceholderImg from "lib/assets/placeholder-image.png";
import { SVGFromUrl } from "lib/components/SVGFromUrl";
import { RemoveBadgeField } from "./RemoveBadgeField";

function CustomToolbar(props: ToolbarProps & { onCancel: () => void }) {
  return (
    <Toolbar
      {...props}
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <RemoveBadgeField />
      <SaveButton />
    </Toolbar>
  );
}

export function EditBadgeButton(): JSX.Element {
  const [open, setOpen] = useState(false);
  const record = useRecordContext();
  const refresh = useRefresh();

  const handleSuccess = () => {
    setOpen(false);
    refresh();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        size="small"
        color="primary"
        startIcon={<EditIcon />}
      >
        Edit
      </Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span>Edit Badge</span>
            <IconButton onClick={handleCancel}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <Edit
          id={record.id.toString()}
          resource="badges"
          basePath="/badges"
          mutationMode="pessimistic"
          onSuccess={handleSuccess}
        >
          <SimpleForm toolbar={<CustomToolbar onCancel={handleCancel} />}>
            <TextInput
              source="name"
              validate={[required()]}
              style={{ width: "100%" }}
            />
            <TextInput
              source="description"
              multiline
              style={{ width: "100%" }}
            />
            <NumberInput
              source="amountToEarn"
              validate={[required()]}
              style={{ width: "100%" }}
            />
            <Box mt={2}>
              <ImageInput
                source="activeImageUrl"
                label="Active Badge Image"
                multiple={false}
                accept="image/svg+xml"
              >
                <FunctionField
                  render={(record) => {
                    const url = record?.rawFile
                      ? URL.createObjectURL(record.rawFile)
                      : record;

                    return (
                      <div style={{ width: "200px", height: "200px" }}>
                        <SVGFromUrl
                          url={typeof url === "string" ? url : PlaceholderImg}
                          fallback={
                            <img
                              src={PlaceholderImg}
                              alt="active badge"
                              style={{ width: "100%" }}
                            />
                          }
                        />
                      </div>
                    );
                  }}
                />
              </ImageInput>
            </Box>
            <Box mt={2}>
              <ImageInput
                source="inactiveImageUrl"
                label="Inactive Badge Image"
                multiple={false}
                accept="image/svg+xml"
              >
                <FunctionField
                  render={(record) => {
                    const url = record?.rawFile
                      ? URL.createObjectURL(record.rawFile)
                      : record;
                    return (
                      <div style={{ width: "200px", height: "200px" }}>
                        <SVGFromUrl
                          url={typeof url === "string" ? url : PlaceholderImg}
                          fallback={
                            <img
                              src={PlaceholderImg}
                              alt="inactive badge"
                              style={{ width: "100%" }}
                            />
                          }
                        />
                      </div>
                    );
                  }}
                />
              </ImageInput>
            </Box>
          </SimpleForm>
        </Edit>
      </Dialog>
    </>
  );
}
